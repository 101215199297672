import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridColumn } from '../Models/LsDataGridConfig';
import { ActiveFilter, GridFilter, GridFilterModalComponent } from '../grid-filter-modal/grid-filter-modal.component';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { CoreComponent } from 'src/app/Components/CoreComponent';
import copy from 'fast-copy';
import { GridSort, SortDirection } from '@limestone/ls-shared-modules';

@Component({
  selector: 'ls-data-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss']
})
export class GridHeaderComponent extends CoreComponent implements OnInit {
  @Input() column: GridColumn;
  @Input() activeFilters$: BehaviorSubject<Map<string, ActiveFilter[]>>;
  @Input() activeSorts$: BehaviorSubject<GridSort[]>;
  @Input() groupByColumns: string[];

  @Output() sortChanged: EventEmitter<GridSort> = new EventEmitter<GridSort>();
  @Output() filterChanged: EventEmitter<Map<string, ActiveFilter[]>> = new EventEmitter<Map<string, ActiveFilter[]>>();
  @Output() groupToggleClicked: EventEmitter<GridColumn> = new EventEmitter<GridColumn>();

  private _filter: Map<string, ActiveFilter[]> = new Map<string, ActiveFilter[]>();
  public _sort: GridSort;

  get columnName(): string {
    return this.column.dataType === 'autocomplete' ? this.column.columnAlias : this.column.value;
  }

  constructor(private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.activeFilters$
      .pipe(
        takeUntil(this.componentTeardown$),
        filter((filters) => !!filters),
        map((filters) => (this._filter = filters))
      )
      .subscribe();
    this.activeSorts$
      .pipe(
        takeUntil(this.componentTeardown$),
        filter((sorts) => !!sorts),
        map((sorts) => (this._sort = copy(sorts.find((s) => s && s.active === this.columnName))))
      )
      .subscribe();
  }

  /**
   * Adds number to badge on column to show number of active filters and sort.
   */
  public activeActionCount(): number {
    let count = this.getFilterCount();
    if (this._sort && this._sort.active === this.columnName) {
      count++;
    }
    if (this.groupByColumns.includes(this.columnName)) {
      count++;
    }
    return count;
  }

  /**
   * Returns count of active filters based on column.
   */
  public getFilterCount(): number {
    if (this._filter.has(this.columnName)) {
      if (this._filter.get(this.columnName).length > 0) {
        return this._filter.get(this.columnName).length;
      }
    }

    return null;
  }

  /**
   * Sorts the current column.
   */
  public sort(): void {
    if (this._sort && this._sort.active === this.columnName) {
      if (this._sort.direction === SortDirection.ASC) {
        this._sort.direction = SortDirection.DESC;
      } else if (this._sort.direction === SortDirection.DESC) {
        this._sort.direction = SortDirection.EMPTY;
      }
    } else {
      this._sort = {
        active: this.columnName,
        type: this.column.dataType,
        direction: SortDirection.ASC
      };
    }
    this.sortChanged.emit(this._sort);
    // Remove sort, do this after the emit so that the handling control can remove the proper column from its sort array.
    if (this._sort.direction === SortDirection.EMPTY) {
      this._sort = null;
    }
  }

  /**
   * Checks if the current column has sorting, grouping or filtering enabled.
   */
  public hasActions(): boolean {
    return this.column.enableSort || this.column.enableGrouping || this.column.enableFilter;
  }

  /**
   * Emits when the group toggle button is clicked.
   *
   * @param event Click event of the button.
   */
  public emitGroupToggle(event: Event): void {
    event.stopPropagation();
    this.groupToggleClicked.emit(this.column);
  }

  /**
   * Opens filter and emits filter values when closed.
   */
  public openFilterModal(): void {
    const dataType = this.column.dataType;
    const data: GridFilter = {
      column: this.column.label,
      columnValueName: this.columnName,
      columnDataType: dataType,
      filters: this._filter.has(this.columnName) ? this._filter.get(this.columnName) : [],
      allowContains: this.column.allowContains,
      columnValidation: this.column.validation
    };
    if (dataType === 'select') {
      data.columnOptions = this.column.dropdownOptions;
    }

    this.dialog
      .open(GridFilterModalComponent, {
        data,
        width: '35%'
      })
      .afterClosed()
      .pipe(
        take(1),
        filter((output: ActiveFilter[]) => !!output),
        map((output: ActiveFilter[]) => {
          if (this._filter.has(this.columnName) && output.length === 0) {
            this._filter.delete(this.columnName);
          } else {
            this._filter.set(this.columnName, output);
          }
          this.filterChanged.next(this._filter);
        })
      )
      .subscribe();
  }
}
