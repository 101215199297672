import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { ActivePage, ExportRequest, Page, PageRequest, Report, ReportType, ReportTypes } from '../../../../Models';
import { FilterOption } from '../../../../Components/filter-button-toggle/filter-button-toggle.component';

export abstract class ReportsActions extends LsAction {
  static REPORT_TYPES: Readonly<string> = 'REPORT_TYPES';
  static REPORT_FILTER_OPTIONS: Readonly<string> = 'REPORT_FILTER_OPTIONS';
  static REPORT_DATA: Readonly<string> = 'REPORT_DATA';
  static REPORT_DATA_ACTIVE_PAGE: Readonly<string> = 'REPORT_DATA_ACTIVE_PAGE';
  static REPORT_DATA_EXPORT: Readonly<string> = 'REPORT_DATA_EXPORT';

  static getReportTypes = createAction(ReportsActions.getActionName(ReportsActions.REPORT_TYPES, ActionType.GET));

  static setReportTypes = createAction(
    ReportsActions.getActionName(ReportsActions.REPORT_TYPES, ActionType.SET),
    props<{ reportTypes: ReportType[] }>()
  );

  static setFilterOptions = createAction(
    ReportsActions.getActionName(ReportsActions.REPORT_FILTER_OPTIONS, ActionType.SET),
    props<{ filterOptions: FilterOption[] }>()
  );

  static getReportDataPage = createAction(
    ReportsActions.getActionName(ReportsActions.REPORT_DATA, ActionType.GET),
    props<{ reportType: ReportTypes; pageRequest: PageRequest }>()
  );

  static setReportDataPage = createAction(
    ReportsActions.getActionName(ReportsActions.REPORT_DATA, ActionType.SET),
    props<{ reportDataPage: Page<any> }>()
  );

  static clearReportDataPages = createAction(
    ReportsActions.getActionName(ReportsActions.REPORT_DATA, ActionType.CLEAR)
  );

  static setReportDataActivePage = createAction(
    ReportsActions.getActionName(ReportsActions.REPORT_DATA_ACTIVE_PAGE, ActionType.SET),
    props<{ activePage: ActivePage }>()
  );

  static triggerReportExport = createAction(
    ReportsActions.getActionName(ReportsActions.REPORT_DATA_EXPORT, ActionType.TRIGGER),
    props<{ fileName: string; reportType: ReportTypes; exportRequest: ExportRequest }>()
  );

  static reportExportSuccessful = createAction(
    ReportsActions.getActionName(ReportsActions.REPORT_DATA_EXPORT, ActionType.COMPLETE)
  );

  static reportExportUnsuccessful = createAction(
    ReportsActions.getActionName(ReportsActions.REPORT_DATA_EXPORT, ActionType.ERROR),
    props<{ errors: string[] }>()
  );
}
