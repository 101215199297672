import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { ReportsState } from './reports-reducer';

@Injectable()
export class ReportsSelectors {
  appState = (state: any) => state.reports;

  selectReportTypes = createSelector(this.appState, (state: ReportsState) => copy(state.reportTypes));

  selectFilterOptions = createSelector(this.appState, (state: ReportsState) => copy(state.filterOptions));

  selectReportDataActivePage = createSelector(this.appState, (state: ReportsState) => copy(state.reportDataActivePage));

  selectReportComponentState = createSelector(this.appState, (state: ReportsState) => ({
    reportDataPages: copy(state.reportDataPages),
    reportDataActivePage: copy(state.reportDataActivePage)
  }));
}
