// @ts-ignore
import { RemitLineItem } from './RemitLineItem';
import { ParticipantOffer } from './ParticipantOffer';
import { OperationsOwner } from './OperationsOwner';

export class FinancingInboundDetails {
  facilityGroupName: string;
  facilityGroupId: number;
  participantGroupId: number;
  originator: string;
  originatorId: number;
  role: string;
  proposedEffectiveDate: Date;
  effectiveDate: Date;
  compartment: string;
  compartmentId: number;
  remittancePurpose: string;
  recipientBankAccount: string;
  paymentMethod: string;
  remittanceReferenceId: number;
  remittanceNetAmount: string;
  currencyCode: string;
  remittanceAllocatedAmount: string;
  remittanceUnallocatedAmount: string;
  receiptDate: Date;
  remitStatusId: string;
  lineItems: RemitLineItem[];
  offers: ParticipantOffer[];
  operationsOwner: string;
  operationsCenterId: number;

  constructor(
    facilityGroupName?: string,
    facilityGroupId?: number,
    participantGroupId?: number,
    originator?: string,
    originatorId?: number,
    role?: string,
    proposedEffectiveDate?: Date,
    effectiveDate?: Date,
    compartment?: string,
    compartmentId?: number,
    remittancePurpose?: string,
    recipientBankAccount?: string,
    paymentMethod?: string,
    remittanceReferenceId?: number,
    remittanceNetAmount?: string,
    currencyCode?: string,
    remittanceAllocatedAmount?: string,
    remittanceUnallocatedAmount?: string,
    receiptDate?: Date,
    remitStatusId?: string,
    lineItems?: RemitLineItem[],
    offers?: ParticipantOffer[],
    operationsOwner?: string,
    operationsCenterId?: number
  ) {
    this.facilityGroupName = facilityGroupName;
    this.facilityGroupId = facilityGroupId;
    this.participantGroupId = participantGroupId;
    this.originator = originator;
    this.originatorId = originatorId;
    this.role = role;
    this.proposedEffectiveDate = proposedEffectiveDate;
    this.effectiveDate = effectiveDate;
    this.compartment = compartment;
    this.compartmentId = compartmentId;
    this.remittancePurpose = remittancePurpose;
    this.recipientBankAccount = recipientBankAccount;
    this.paymentMethod = paymentMethod;
    this.remittanceReferenceId = remittanceReferenceId;
    this.remittanceNetAmount = remittanceNetAmount;
    this.currencyCode = currencyCode;
    this.remittanceAllocatedAmount = remittanceAllocatedAmount;
    this.remittanceUnallocatedAmount = remittanceUnallocatedAmount;
    this.receiptDate = receiptDate;
    this.remitStatusId = remitStatusId;
    this.lineItems = lineItems;
    this.offers = offers;
    this.operationsOwner = operationsOwner;
    this.operationsCenterId = operationsCenterId;
  }
}
