export class CashAppRemitLineItemTotals {
  totalPols: number;
  totalAllocatedAmount: string;

  constructor(totalPols?: number, totalAllocatedAmount?: number | string) {
    this.totalPols = totalPols ?? 0;
    this.totalAllocatedAmount =
      typeof totalAllocatedAmount === 'string'
        ? Number(totalAllocatedAmount).toFixed(2)
        : totalAllocatedAmount?.toFixed(2) ?? '0.00';
  }
}
