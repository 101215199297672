import { PaymentMethod } from './PaymentMethod';
import { CashAppOutboundDetails } from './CashAppOutboundDetails';
import { DateTime } from 'luxon';
import { DateUtils } from '../../Utils/DateUtils';

export class CashAppOutbound {
  public compartmentName: string;
  public compartmentId: number;
  public currencyCode: string;
  public effectiveDate: DateTime;
  public facilityGroupName: string;
  public facilityGroupId: number;
  public paymentMethodId: string;
  public paymentMethodName: string;
  public paymentMethod: PaymentMethod;
  public proposedEffectiveDate: DateTime;
  public receiptDate: DateTime;
  public recipientBankAccountId: number;
  public recipientId: number;
  public recipientName: string;
  public recipientRoleTypeName: string;
  public remittancePurpose: string;
  public remitStatusId: string;
  public remittanceAllocatedAmount: number | string;
  public remittanceNetAmount: number | string;
  public remittanceReferenceId: number;
  public remittanceUnallocatedAmount: number | string;
  public operationsOwnerName: string;
  public remitTypeId: string;

  constructor(
    remittanceReferenceId?: number,
    remitStatusId?: string,
    compartmentName?: string,
    compartmentId?: number,
    currencyCode?: string,
    effectiveDate?: string | DateTime,
    facilityGroupName?: string,
    facilityGroupId?: number,
    paymentMethodId?: string,
    paymentMethodName?: string,
    proposedEffectiveDate?: string | DateTime,
    receiptDate?: string | DateTime,
    recipientBankAccountId?: number,
    recipientId?: number,
    recipientName?: string,
    recipientRoleTypeName?: string,
    remittanceAllocatedAmount?: number | string,
    remittanceNetAmount?: number | string,
    remittancePurpose?: string,
    remittanceUnallocatedAmount?: number | string,
    operationsOwnerName?: string,
    remitTypeId?: string
  ) {
    this.compartmentName = compartmentName;
    this.compartmentId = compartmentId;
    this.currencyCode = currencyCode;
    this.effectiveDate = DateUtils.getDateTime(effectiveDate);
    this.facilityGroupName = facilityGroupName;
    this.facilityGroupId = facilityGroupId;
    this.paymentMethodName = paymentMethodName;
    this.paymentMethodId = paymentMethodId;
    this.paymentMethod = new PaymentMethod(paymentMethodId, paymentMethodName, true);
    this.proposedEffectiveDate = DateUtils.getDateTime(proposedEffectiveDate);
    this.receiptDate = DateUtils.getDateTime(receiptDate);
    this.recipientBankAccountId = recipientBankAccountId;
    this.recipientId = recipientId;
    this.recipientName = recipientName;
    this.recipientRoleTypeName = recipientRoleTypeName;
    this.remitStatusId = remitStatusId;
    this.remittanceAllocatedAmount = remittanceAllocatedAmount;
    this.remittanceNetAmount = remittanceNetAmount;
    this.remittancePurpose = remittancePurpose;
    this.remittanceReferenceId = remittanceReferenceId;
    this.remittanceUnallocatedAmount = remittanceUnallocatedAmount;
    this.operationsOwnerName = operationsOwnerName;
    this.remitTypeId = remitTypeId;
  }

  public static FromCashAppOutboundDetails(cashAppOutboundDetails: CashAppOutboundDetails) {
    return new CashAppOutbound(
      cashAppOutboundDetails.remittanceReferenceId,
      cashAppOutboundDetails.remitStatusId,
      cashAppOutboundDetails.compartment,
      cashAppOutboundDetails.compartmentId,
      cashAppOutboundDetails.currencyCode,
      DateTime.fromJSDate(cashAppOutboundDetails.effectiveDate),
      cashAppOutboundDetails.facilityGroupName,
      cashAppOutboundDetails.facilityGroupId,
      cashAppOutboundDetails.paymentMethodId,
      cashAppOutboundDetails.paymentMethod,
      DateTime.fromJSDate(cashAppOutboundDetails.proposedEffectiveDate),
      DateTime.fromJSDate(cashAppOutboundDetails.receiptDate),
      cashAppOutboundDetails.recipientBankAccountId,
      cashAppOutboundDetails.recipientId,
      cashAppOutboundDetails.recipient,
      cashAppOutboundDetails.role,
      cashAppOutboundDetails.remittanceAllocatedAmount.toString(),
      cashAppOutboundDetails.remittanceNetAmount.toString(),
      cashAppOutboundDetails.remittancePurpose,
      cashAppOutboundDetails.remittanceUnallocatedAmount.toString(),
      cashAppOutboundDetails.operationsOwner,
      cashAppOutboundDetails.remitTypeId
    );
  }
}
