import { GridColumn } from '../../Components/App Components/data-grid/Models/LsDataGridConfig';
import { ReportTypes } from '../ReportTypes';
import { ActivePage } from '../ActivePage';
import { GridSort } from '@limestone/ls-shared-modules';

export abstract class ReportConfig {
  gridColumns: GridColumn[];
  reportType: ReportTypes;
  activePage: ActivePage;
  fileName: string;

  static readonly initialPageSize = 25;

  protected buildActivePageConfig(sort: GridSort[]) {
    return new ActivePage(
      {
        pageIndex: 0,
        pageSize: ReportConfig.initialPageSize,
        length: 0
      },
      sort,
      null
    );
  }
}
