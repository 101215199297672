import { ParticipantOffer } from './ParticipantOffer';
import { DateTime } from 'luxon';
import { DateUtils } from '../../Utils/DateUtils';
import { PaymentMethod } from './PaymentMethod';
import { CashAppRemitLineItem } from './CashAppRemitLineItem';

export class CashAppInboundDetails {
  facilityGroupName: string;
  facilityGroupId: number;
  participantGroupId: number;
  originator: string;
  originatorId: number;
  role: string;
  proposedEffectiveDate: DateTime;
  effectiveDate: DateTime;
  compartment: string;
  compartmentId: number;
  remittancePurpose: string;
  recipientBankAccount: string;
  paymentMethod: string | PaymentMethod;
  operationsOwner: string;
  remittanceReferenceId: number;
  remitTypeId: string;
  remittanceNetAmount: string;
  currencyCode: string;
  remittanceAllocatedAmount: string;
  remittanceUnallocatedAmount: string;
  receiptDate: DateTime;
  remitStatusId: string;
  lineItems: CashAppRemitLineItem[];
  offers: ParticipantOffer[];
  submittedById: number | null;
  approvedById: number | null;

  constructor(
    facilityGroupName?: string,
    facilityGroupId?: number,
    participantGroupId?: number,
    originator?: string,
    originatorId?: number,
    role?: string,
    proposedEffectiveDate?: DateTime,
    effectiveDate?: DateTime,
    compartment?: string,
    compartmentId?: number,
    remittancePurpose?: string,
    recipientBankAccount?: string,
    paymentMethod?: string | PaymentMethod,
    remittanceReferenceId?: number,
    remitTypeId?: string,
    remittanceNetAmount?: string,
    currencyCode?: string,
    remittanceAllocatedAmount?: string,
    remittanceUnallocatedAmount?: string,
    receiptDate?: DateTime,
    remitStatusId?: string,
    lineItems?: CashAppRemitLineItem[],
    offers?: ParticipantOffer[],
    submittedById?: number | null,
    approvedById?: number | null,
    operationsOwner?: string
  ) {
    this.facilityGroupName = facilityGroupName;
    this.facilityGroupId = facilityGroupId;
    this.participantGroupId = participantGroupId;
    this.originator = originator;
    this.originatorId = originatorId;
    this.role = role;
    this.proposedEffectiveDate = proposedEffectiveDate ? DateUtils.getDateTime(proposedEffectiveDate) : null;
    this.effectiveDate = effectiveDate ? DateUtils.getDateTime(effectiveDate) : null;
    this.compartment = compartment;
    this.compartmentId = compartmentId;
    this.remittancePurpose = remittancePurpose;
    this.recipientBankAccount = recipientBankAccount;
    this.paymentMethod = paymentMethod;
    this.operationsOwner = operationsOwner;
    this.remittanceReferenceId = remittanceReferenceId;
    this.remitTypeId = remitTypeId;
    this.remittanceNetAmount = remittanceNetAmount;
    this.currencyCode = currencyCode;
    this.remittanceAllocatedAmount = remittanceAllocatedAmount;
    this.remittanceUnallocatedAmount = remittanceUnallocatedAmount;
    this.receiptDate = receiptDate ? DateUtils.getDateTime(receiptDate) : null;
    this.remitStatusId = remitStatusId;
    this.lineItems = lineItems
      ? lineItems.map(
          (li) =>
            new CashAppRemitLineItem(
              li.polId,
              li.instrument,
              li.instrumentId,
              li.seller,
              li.sellerId,
              li.debtor,
              li.debtorId,
              li.participant,
              li.lineItemType,
              li.currency,
              li.lineItemAllocatedAmount,
              li.facilityGroupId,
              li.participantBalanceMemo,
              li.lineItemAdjustedAmount,
              li.lineItemAdjustmentCode,
              li.lineItemId
            )
        )
      : new Array<CashAppRemitLineItem>();
    this.offers = offers;
    this.submittedById = submittedById ?? null;
    this.approvedById = approvedById ?? null;
  }
}
