export class Remittance {
  facilityGroupId: number;
  compartmentId: number;
  currencyCode: string;
  remittanceNetAmount: number;
  originatorId: number;
  receiptDate: Date;
  paymentMethodId?: string;
  effectiveDate?: Date;
  operationsOwnerId?: number;
}
