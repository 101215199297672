import { RemitLineItem } from './RemitLineItem';

export class CashAppRemitLineItem extends RemitLineItem {
  participantBalanceMemo: number;
  lineItemAdjustedAmount: number;
  lineItemAdjustmentCode: string;

  constructor(
    polId?: number,
    instrument?: string,
    instrumentId?: number,
    seller?: string,
    sellerId?: number,
    debtor?: string,
    debtorId?: number,
    participant?: string,
    lineItemType?: string,
    currency?: string,
    lineItemAllocatedAmount?: number,
    facilityGroupId?: number,
    participantBalanceMemo?: number,
    lineItemAdjustedAmount?: number,
    lineItemAdjustmentCode?: string,
    lineItemId?: number
  ) {
    super(
      polId,
      instrument,
      instrumentId,
      seller,
      sellerId,
      debtor,
      debtorId,
      participant,
      lineItemType,
      currency,
      lineItemAllocatedAmount,
      facilityGroupId,
      lineItemId
    );
    this.participantBalanceMemo = participantBalanceMemo;
    this.lineItemAdjustedAmount = lineItemAdjustedAmount;
    this.lineItemAdjustmentCode = lineItemAdjustmentCode;
  }
}
