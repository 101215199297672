import { DateTime } from 'luxon';

export class ManualRemitLineItem {
  instrument: string;
  polId: number;
  currency: string;
  netAmount: number;
  allocatedAmount?: number;
  advanceAmount: number;
  reserveAmount: number;
  participantBalanceMemo?: number;
  participant: string;
  seller: string;
  debtor: string;
  financeStatus: string;
  expectedDueDate: DateTime;
  remitRefId: number;

  constructor(
    instrument?: string,
    polId?: number,
    currency?: string,
    netAmount?: number,
    allocatedAmount?: number,
    advanceAmount?: number,
    reserveAmount?: number,
    participantBalanceMemo?: number,
    participant?: string,
    seller?: string,
    debtor?: string,
    financeStatus?: string,
    expectedDueDate?: DateTime,
    remitRefId?: number
  ) {
    this.instrument = instrument;
    this.polId = polId;
    this.currency = currency;
    this.netAmount = netAmount;
    this.allocatedAmount = allocatedAmount;
    this.advanceAmount = advanceAmount;
    this.reserveAmount = reserveAmount;
    this.participantBalanceMemo = participantBalanceMemo;
    this.participant = participant;
    this.seller = seller;
    this.debtor = debtor;
    this.financeStatus = financeStatus;
    this.expectedDueDate = expectedDueDate;
    this.remitRefId = remitRefId;
  }
}
