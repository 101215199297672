import { ReportConfig } from './ReportConfig';
import { ReportTypes } from '../ReportTypes';
import { SellerInstrumentListConfig } from './SellerInstrumentListConfig';
import { SellerOnboardingConfig } from './SellerOnboardingConfig';

export class ReportConfigFactory {
  static createConfig(reportType: ReportTypes): ReportConfig {
    switch (reportType) {
      case ReportTypes.SELL_INST:
        return new SellerInstrumentListConfig();
      case ReportTypes.SELL_ONBD:
        return new SellerOnboardingConfig();
      default:
        throw new Error(`Report type ${reportType} is not supported`);
    }
  }
}
